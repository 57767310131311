import React from 'react'

function Footer() {
  return (
    <div>
              <footer className="footer">
        <div className="container">
          <div className="footer__main">
            <div className="row"> 
              <div className="col-xl-4 col-md-8">
                <div className="info">
                  <a href="/" className="logo" >
                    
                    <img src="assets/images/logo/log-footer.png" alt="" />
                  </a>
                  <h6>Join our Community 🤙</h6>
                  < p  style={{visibility:"hidden"}}>1</p>
                  <ul className="list-social">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100086988191584" target="_blank">
                        <span className="icon-facebook-f" /> Facebook Community
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/risingcoin_/?igshid=ZDdkNTZiNTM%3D" target="_blank">
                        <span className="icon-instagram" /> Instagram Community
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@risingcoin_" target="_blank">
                        <span className="icon-youtube" /> Youtube Community
                      </a>
                    </li>
                    <li>
                      <a href="https://discord.gg/8jQfa7JNeM" target="_blank">
                      <i className="fa-brands fa-discord" style={{marginRight:"5px"}}></i>Discord
                      </a>
                    </li>
                    
                    <li>
                      <a href="https://twitter.com/Risingcoin_rsc?t=3y--KjDHmZuTGT-4NJQ-Mg&s=09"  target="_blank">
                        <span className="icon-twitter" /> Twitter Community
                      </a>
                    </li>

                    <li>
                      <a href="https://t.me/risingcoin_rsc" target="_blank">

                      <i class="fa fa-telegram"  style={{marginRight:"5px"}} ></i>
                      Telegram Community
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-4">
                <div className="widget-link s1">
                  <h6 className="title">PRODUCTS</h6>
                  <ul>

                    <li>
                      <a href="/trade">Dex classifieds Marketplace</a>
                    </li>
                    <li>
                      <a href="#">Dex Social</a>
                    </li>
                    <li>
                      <a href="#">Dex Newsified</a>
                    </li>
                    <li>
                      <a href="#">Live</a>
                    </li>
                    <li>
                      <a href="#">staking</a>
                    </li>
                    <li>
                      <a href="#">Others</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-4">
                <div className="widget-link s2">
                  <h6 className="title">SERVICES</h6>



                  <ul>
                    <li>
                      <a href="/trade">Trading</a>
                    </li>
                    <li>
                      <a href="/markets">Market update</a>
                    </li>
                    <li>
                      <a href="#">Charity/Disasters</a>
                    </li>
                    <li>
                      <a href="#">Exchange</a>
                    </li>
                    <li>
                      <a href="#">Build on RSC Blockchain</a>
                    </li>
                 
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-4">
                <div className="widget-link s3">
                  <h6 className="title">SUPPORT</h6>
                  <ul>




                    <li>
                      <a href="/education">RisingCoin Learn</a>
                    </li>
                    <li>
                      <a href="#">Help Center</a>
                    </li>
                    <li>
                      <a href="user-profile.html">User Feedback</a>
                    </li>
                    <li>
                      <a href="#">Submit a request</a>
                    </li>
                    <li>
                      <a href="#">API Documentation</a>
                    </li>
                    <li>
                      <a href="#">Report Bug</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-4">
                <div className="widget-link s4">
                  <h6 className="/community">ABOUT US</h6>
                  <ul>
                    <li>
                      <a href="#">About RisingCoin</a>
                    </li>
                    <li>
                      <a href="#">Authenticity Check</a>
                    </li>
                    <li>
                      <a href="#">Careers</a>
                    </li>
                
                    <li>
                      <a href="#">Blog</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg">
          <div className="footer__bottom">
            <p>Copyright © 2023 RisingCoin</p>
            <ul className="list-social">
              <li>
                <a href="https://www.facebook.com/profile.php?id=100086988191584" target="_blank">
                  <i className="fa-brands fa-facebook" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/Risingcoin_rsc?t=3y--KjDHmZuTGT-4NJQ-Mg&s=09" target="_blank">
                  <i className="fa-brands fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/risingcoin_?igshid=ZDdkNTZiNTM=" target="_blank">
                  <i className="fa-brands fa-instagram" />
                </a>
              </li>
              <li>
                <a href="https://discord.gg/8jQfa7JNeM"  target="_blank" >
                  <i className="fa-brands fa-discord"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer