import React from 'react'
import roadmap from "../images/roadmap.png"
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import { Box } from '@mui/material';

function Roadmap() {
  return (

    <div style={{position:"relative"}}>


      <div>
        <main className="flex flex-col flex-1 justify-center" id="main">
          <section className="relative bg-light text-copy text-center" style={{}}>
            <div className="mx-auto px-4 md:px-6 xl:px-8 2xl:px-12 prose-sm md:prose lg:prose-xl relative z-10 pt-14 md:pt-28 pb-14 md:pb-28 max-w-5xl">
              <h1 className="max-w-4xl mx-auto">Rising Coin Roadmap</h1>
              <p className="lead mx-auto md:px-6 max-w-3xl">
             Rising coin roadmap  will be reviewed every  year interval. The launch will began in 2022 on bsc chain before moving to its layer 1 POS blockchain. </p>

           
            </div>
          </section>
         
        </main>
      </div>
   <div  style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",paddingBlock:"4rem" }}>

    <div style={{width:"80%"}}>
    <img src ={roadmap} style={{width:"100%", height:"100%", borderRadius:"1rem"}}/>

    </div>
       
    </div>


    <Footer/>

  
    </div>
 
  )
}

export default Roadmap