import React from 'react'
import { useState,useContext } from 'react';
import {dataContex} from './DataContex';

function Market2() {



  const {
  
    market,
    // trending,
    // aptos,
//   ai,
//   bsc,
// cardano,
// exchanges,
// defi,
// derivatives,
// entertainment,
// ethereumecosystem,
// gaming, 
// nft,
// realestate,
// dot,
// predictionmarket,
// syntheticasset

  
  } = useContext(dataContex)

  const [data, setData] = useState(market)


 

  



  if( !market){
    return null
  }
     
  console.log(market)


    
  return (
    <section className="crypto">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="crypto__main">
            <div className="flat-tabs">
              <ul className="menu-tab">
                <li className="active"    style={{backgroundColor:"#0e0637"}}><h6 className="fs-16">Top Cryptocurrency</h6></li>
               
           
              </ul>

          
              <div className="content-tab">
                <div className="content-inner">
                 
                 



        {
 
        
        market.map((data,index)=>{
  return(
    <div className="crypto-box">
    <div className="left">
<span>
  <img src={data.image}  style={{width:"30px", height:"30px"}}/>
</span>
      <h6 style={{fontSize:"16px"}}>{data.symbol}</h6>
      <h6 className="price">${data.current_price
}</h6>
    </div>

    <div className="right" >
      {/* <div id="total-revenue-chart-1"></div> */}
      <p className="sale success"  style= {{ backgroundColor:data.price_change_percentage_24h >= 0 ? "green": "red"}}>
        <svg width="8" height="12" viewbox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.393244 5.90568C0.477403 6.06168 0.637433 6.15893 0.811488 6.15893H3.52179V11.5105C3.52179 11.7807 3.73601 12 3.99996 12C4.26392 12 4.47814 11.7807 4.47814 11.5105V6.15893H7.18844C7.36313 6.15893 7.52316 6.06168 7.60668 5.90568C7.69148 5.74969 7.68574 5.5591 7.59329 5.40832L4.40482 0.228447C4.31683 0.0861572 4.16445 0 3.99996 0C3.83547 0 3.68309 0.0861572 3.59511 0.228447L0.406633 5.40832C0.358178 5.48795 0.333313 5.57868 0.333313 5.6694C0.333313 5.75034 0.353715 5.83193 0.393244 5.90568Z" fill="white"></path>
        </svg>
        {data.price_change_percentage_24h

}
      </p>
    {/* <p className="unit">{data.symbol}</p> */}
    </div>
  </div>
  )
})  

        } 

            

             </div>
          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </section>
  )
}

export default Market2