
import React from 'react'
import { Link } from 'react-router-dom'

import { useState,useContext } from 'react';
import {dataContex} from './DataContex';


function Market3() {
  
  
  const {
  
    market,
    trending,
    aptos,
  ai,
  bsc,
cardano,
exchanges,
defi,
derivatives,
entertainment,
ethereumecosystem,
gaming, 
nft,
realestate,
dot,
predictionmarket,
syntheticasset

  
  } = useContext(dataContex)

  
  const [showEth, setShowEth] = useState(true)
  const [showBSC, setShowBSC] = useState(false)
  const [showNFT, setShowNFT] = useState(false)
  const [showDEFI, setShowDEFI] = useState(false)
  const [showRealEstate, setShowRealEstate] = useState(false)
  const [showGaming, setShowGaming] = useState(false)
  const [showPrediction, setShowPrediction] = useState(false)
 
  


 
  if( !bsc){
    return null
  }

  return (




    <section className="coin-list">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="block-text">
            <h3 className="heading">Market Update</h3>
            <a className="btn-action-2" href="#">See All Coins</a>
          </div>
          <div className="coin-list__main">
            <div className="flat-tabs">
              <ul className="menu-tab">
                <li className="active"><h6 className="fs-16"   onClick={()=>{setShowEth(true); setShowNFT(false); setShowRealEstate(false);setShowDEFI(false) ; setShowBSC(false); setShowGaming(false); setShowPrediction(false);}}>ETH</h6></li>
                <li><h6 className="fs-16"   onClick={()=>{setShowNFT(false); setShowEth(false); setShowRealEstate(false);setShowDEFI(false) ; setShowBSC(!showBSC); setShowGaming(false); setShowPrediction(false);}}>BSC</h6></li>
                <li><h6 className="fs-16" onClick={()=>{setShowNFT(!showNFT); setShowEth(false); setShowRealEstate(false);setShowDEFI(false) ; setShowBSC(false); setShowGaming(false); setShowPrediction(false);}}>NFT</h6></li>
                <li><h6 className="fs-16" onClick={()=>{setShowNFT(false); setShowEth(false); setShowRealEstate(false);setShowDEFI(!showDEFI) ; setShowBSC(false); setShowGaming(false); setShowPrediction(false); }}>DEFI</h6></li>
                <li><h6 className="fs-16" onClick={()=>{setShowNFT(false); setShowEth(false); setShowRealEstate(!showRealEstate);setShowDEFI(false) ; setShowBSC(false); setShowGaming(false); setShowPrediction(false);}}>Real Estate</h6></li>
                <li><h6 className="fs-16" onClick={()=>{setShowNFT(false); setShowEth(false); setShowRealEstate(false);setShowDEFI(false) ; setShowBSC(false); setShowGaming(!showGaming); setShowPrediction(false);}}>Gaming</h6></li>
                <li><h6 className="fs-16" onClick={()=>{setShowNFT(false); setShowEth(false); setShowRealEstate(false);setShowDEFI(false) ; setShowBSC(false); setShowGaming(false); setShowPrediction(!showPrediction); }}>Prediction Market</h6></li>
              </ul>
              <div className="content-tab">
                <div className="content-inner">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" />
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Last Price</th>
                        <th scope="col">24h %</th>
                        <th scope="col">Market Cap</th>
                        <th scope="col">Volume</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                   
                   
              


                    {showEth &&   <tbody>
                {  ethereumecosystem.map( (data, index) =>{
                  return(
                    <tr>
                    <th scope="row"><span className="icon-star" /></th>
                    <td>{index + 1}</td>
                    <td>
                      <a href="#">
                      <span>
  <img src={data.image}  style={{width:"30px", height:"30px"}}/>
</span>
                        <span>{ data.id}</span>
                        <span className="unit">{ data.symbol}</span></a>
                    </td>
                    <td className="boild">${data.current_price
}</td>
                    <td className="up"  style= {{ color:data.price_change_percentage_24h >= 0 ? "green": "red"}}>       
        {data.price_change_percentage_24h

}%</td>
                    <td className="boild">${ data.market_cap

}</td>

<td className="boild">${ data.total_volume
}</td>
               
                    <td><a href="#" className="btn">Trade</a></td>
                  </tr>
                  )
                     
                }

                 )}
          
                    </tbody> }



                    { showBSC && <tbody>
                {bsc.map( (data, index) =>{
                  return(
                    <tr>
                    <th scope="row"><span className="icon-star" /></th>
                    <td>{index + 1}</td>
                    <td>
                      <a href="#">
                      <span>
  <img src={data.image}  style={{width:"30px", height:"30px"}}/>
</span>
                        <span>{ data.id}</span>
                        <span className="unit">{ data.symbol}</span></a>
                    </td>
                    <td className="boild">${data.current_price
}</td>
                    <td className="up"  style= {{ color:data.price_change_percentage_24h >= 0 ? "green": "red"}}>       
        {data.price_change_percentage_24h

}%</td>
                    <td className="boild">${ data.market_cap
}</td>

<td className="boild">${ data.total_volume
}</td>
               
                    <td><a href="#" className="btn">Trade</a></td>
                  </tr>
                  )
                     
                }

                 )}
          
                    </tbody> }



                    { showNFT && <tbody>
                {nft.map( (data, index) =>{
                  return(
                    <tr>
                    <th scope="row"><span className="icon-star" /></th>
                    <td>{index + 1}</td>
                    <td>
                      <a href="#">
                      <span>
  <img src={data.image}  style={{width:"30px", height:"30px"}}/>
</span>
                        <span>{ data.id}</span>
                        <span className="unit">{ data.symbol}</span></a>
                    </td>
                    <td className="boild">${data.current_price
}</td>
                    <td className="up"  style= {{ color:data.price_change_percentage_24h >= 0 ? "green": "red"}}>       
        {data.price_change_percentage_24h

}%</td>
                    <td className="boild">${ data.market_cap
}</td>

<td className="boild">${ data.total_volume
}</td>
               
                    <td><a href="#" className="btn">Trade</a></td>
                  </tr>
                  )
                     
                }

                 )}
          
                    </tbody> }



                    { showDEFI && <tbody>
                {defi.map( (data, index) =>{
                  return(
                    <tr>
                    <th scope="row"><span className="icon-star" /></th>
                    <td>{index + 1}</td>
                    <td>
                      <a href="#">
                      <span>
  <img src={data.image}  style={{width:"30px", height:"30px"}}/>
</span>
                        <span>{ data.id}</span>
                        <span className="unit">{ data.symbol}</span></a>
                    </td>
                    <td className="boild">${data.current_price
}</td>
                    <td className="up"  style= {{ color:data.price_change_percentage_24h >= 0 ? "green": "red"}}>       
        {data.price_change_percentage_24h

}%</td>
                    <td className="boild">${ data.market_cap
}</td>

<td className="boild">${ data.total_volume
}</td>
               
                    <td><a href="#" className="btn">Trade</a></td>
                  </tr>
                  )
                     
                }

                 )}
          
                    </tbody> }



                    { showRealEstate && <tbody>
                {realestate.map( (data, index) =>{
                  return(
                    <tr>
                    <th scope="row"><span className="icon-star" /></th>
                    <td>{index + 1}</td>
                    <td>
                      <a href="#">
                      <span>
  <img src={data.image}  style={{width:"30px", height:"30px"}}/>
</span>
                        <span>{ data.id}</span>
                        <span className="unit">{ data.symbol}</span></a>
                    </td>
                    <td className="boild">${data.current_price
}</td>
                    <td className="up"  style= {{ color:data.price_change_percentage_24h >= 0 ? "green": "red"}}>       
        {data.price_change_percentage_24h

}%</td>
                    <td className="boild">${ data.market_cap
}</td>

<td className="boild">${ data.total_volume
}</td>
               
                    <td><a href="#" className="btn">Trade</a></td>
                  </tr>
                  )
                     
                }

                 )}
          
                    </tbody> }



                    {showGaming && <tbody>
                {gaming.map( (data, index) =>{
                  return(
                    <tr>
                    <th scope="row"><span className="icon-star" /></th>
                    <td>{index + 1}</td>
                    <td>
                      <a href="#">
                      <span>
  <img src={data.image}  style={{width:"30px", height:"30px"}}/>
</span>
                        <span>{ data.id}</span>
                        <span className="unit">{ data.symbol}</span></a>
                    </td>
                    <td className="boild">${data.current_price
}</td>
                    <td className="up"  style= {{ color:data.price_change_percentage_24h >= 0 ? "green": "red"}}>       
        {data.price_change_percentage_24h

}%</td>
                    <td className="boild">${ data.market_cap
}</td>

<td className="boild">${ data.total_volume
}</td>

<td className="boild">${ data.total_volume
}</td>
               
                    <td><a href="#" className="btn">Trade</a></td>
                  </tr>
                  )
                     
                }

                 )}
          
                    </tbody> }


                    { showPrediction &&<tbody>
                {predictionmarket.map( (data, index) =>{
                  return(
                    <tr>
                    <th scope="row"><span className="icon-star" /></th>
                    <td>{index + 1}</td>
                    <td>
                      <a href="#">
                      <span>
  <img src={data.image}  style={{width:"30px", height:"30px"}}/>
</span>
                        <span>{ data.id}</span>
                        <span className="unit">{ data.symbol}</span></a>
                    </td>
                    <td className="boild">${data.current_price
}</td>
                    <td className="up"  style= {{ color:data.price_change_percentage_24h >= 0 ? "green": "red"}}>       
        {data.price_change_percentage_24h

}%</td>
                    <td className="boild">${ data.market_cap
}</td>

           <td className="boild">${ data.total_volume
}</td>    
                    <td><a href="#" className="btn">Trade</a></td>
                  </tr>
                  )
                     
                }

                 )}
          
                    </tbody> }
                  </table>
                </div>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Market3