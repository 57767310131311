import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Market from "./pages/Market";
import WhitePaper from "./pages/WhitePaper";
import Education from "./pages/Education";
import Trade from "./pages/Trade";
import Presale from "./pages/Presale";
import Community from "./pages/Community";
import Faq from "./pages/Faq"
import Stake from "./pages/Stake"
import Roadmap from "./pages/Roadmap"




function App() {
  return (
    <Routes>
      
      <Route path="/" element={<Home />} />
      <Route path="/markets" element={<Market/>} />
      <Route path="/education" element={<Education/>} />
      <Route path="/whitepaper" element={<WhitePaper/>} />
      <Route path="/roadmap" element={<Roadmap/>} />
      <Route path="/trade" element={<Trade/>} />
      <Route path="/swap" element={<Presale/>} />
      <Route path="/stake" element={<Stake/>} />
      <Route path="/community" element={<Community/>} />
      <Route path="/faq" element={<Faq/>} />




        
 
     <Route path="dashboard" element={<Faq/>}>
   
   <Route path="created" element={<Faq/>}/>
   <Route path="activity" element={<Faq/>}/>
   <Route path="collections" element={<Faq/>}/>
   <Route path="wallet" element={<Faq />}/>
 </Route>

     
    </Routes>
  );
}

export default App;
