import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { ethers } from "ethers";
import { dataContex } from "./DataContex";
import { useWeb3React } from "@web3-react/core";
import { Alert, Box, Typography } from "@mui/material";
import Market2 from "./Market2";
import Swipers from "../components/Swipers";
import Swipers2 from "../components/Swipers2";
import tokenomics from "../images/tokenomics.png";
import review1 from "../images/1.jpeg";
import review2 from "../images/2.jpeg";


function Home() {
  const { marketdata } = useContext(dataContex);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("0");
  const [value2, setValue2] = useState();
  const [balances, setBalance] = useState();
  const [success, setSuccess] = useState();

  const [mobile, setmobile] = useState(false);
  const { library, chainId, account, activate, deactivate, active } =
    useWeb3React();

  useEffect(() => {
    AOS.init();
  }, []);




  async function execute() {
    setError2(false);
    setError(false);
    setSuccess(false);
    if (active) {
      const contractAddress = "0x48d278C2fe7D72c8BfD62c2f0d3415aAec4b7718";

      const abi =[
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_totalSupply",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "_decimals",
              "type": "uint256"
            },
            {
              "internalType": "string",
              "name": "_name",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "_symbol",
              "type": "string"
            },
            {
              "internalType": "address payable",
              "name": "_wallet",
              "type": "address"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "mine",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "transfer",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "address",
              "name": "_from",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            }
          ],
          "name": "TransferReceived",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "address",
              "name": "_from",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "address",
              "name": "_destAddr",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            }
          ],
          "name": "TransferSent",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "address payable",
              "name": "destAddr",
              "type": "address"
            }
          ],
          "name": "withdraw",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "stateMutability": "payable",
          "type": "receive"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner_",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            }
          ],
          "name": "allowance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "balance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "_who",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "dailyMineLimit",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "dailyMineTotal",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "decimals",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "fourYears",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "halvingInterval",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "lastHalving",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "lastMineTime",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "lastMining",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "maxSupply",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "mingingInterval",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "owner",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "reward",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "timeSinceLastHalving",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "timeSinceLastMined",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "totalSupply",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "totalSupply_",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "wallet",
          "outputs": [
            {
              "internalType": "address payable",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ];

      const signer = library.getSigner();
      const address = await signer.getAddress();

      const contract = new ethers.Contract(contractAddress, abi, signer);

      try {
        const Amount = ethers.utils.parseUnits(value.toString(), 18);

        //  const obj = {address:account,   name:"USDT" }

        // await API.graphql({ query:createSpendingAddress,
        //   variables: { input:obj} ,
        // }

        // );

        const mine = await contract.mine({
          value: ethers.utils.parseUnits("0.00064", "ether"),
          gasLimit: 500000,
        });

        setSuccess(true);

        //  console.log(name)
        //  console.log(approve)
        //     console.log(depositToken)
        // console.log(depositToken)
      } catch (error) {
        setError2(true);
        setMessage(error.message);
        console.log(error);
      }
    } else {
      setError(true);
    }
  }

  return (
    <div>
      {/* Header */}
    

      {/* Banner Top */}
      <section
        className="banner"
        style={{
          backgroundImage: "url(assets/images/background/Assetbanner.png)",
          width:"100vw",
          backgroundRepeat: "repeat-y",
          backgroundPosition: "center",
          position:"relative"
        }}
      >

<Box  sx={{position:"absolute", bottom:{xs:"150px",sm:"0px",}}}>

</Box>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="banner__content">
                <h2 className="title">
                  Get easy access to a crypto that never falls
                </h2>
                <p className="fs-20 desc">
                  RisingCoin is the easiest, safest, and non-volatile way to buy
                  &amp; sell crypto asset exchange.
                </p>
                <a
                  href="https://accounts.risingcoin.xyz/user/register"
               target="_blank"
                  className="btn-action"
                  style={{ backgroundColor: "#0e0637" }}
                >
                  <span>Register</span>
                </a>

                <a
                  href="https://accounts.risingcoin.xyz/user/login"
                  target="_blank"
                  className="btn-action"
                  style={{
                    background: "#fff",
                    color: "#0e0637",
                    border: "solid 1px #0e0637",
                    marginLeft: "1.2rem",
                    marginRight:"1.2rem",
                  }}
                >
                  <span>Login</span>
                </a>

                <a
                  href="https://accounts.risingcoin.xyz/user/login"
               target="_blank"
                  className="btn-action"
                  style={{ backgroundColor: "red" }}
                >
                  <span>Mine RSC</span>
                </a>

                <a
                  href="/swap"
                  
                  className="btn-action"
                  style={{
                    background: "green",
                    color: "#fff",
                    border: "solid 1px #0e0637",
                    marginLeft: "1.2rem",
                    
                  }}
                >
                  <span>Buy/Sell</span>
                </a>

                
                {error && <Alert severity="info"> connet your wallet!!</Alert>}
                {error2 && (
                  <Alert severity="error">
                    {" "}
                    you can mine again in 24hours!!
                  </Alert>
                )}
                {success && (
                  <Alert severity="success">Note! You mine once a day.</Alert>
                )}
                <div className="partner">
                  <h6 className="small">Download Wallet App</h6>
                  <div className="partner__list">
                    <div className>
                      <div className>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.risingcoin.android&hl=en&gl=US"
                          target="_blank"
                        >
                          <img src="https://risingcoin.io/google.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="https://risingcoin.io/apple.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="banner__image">

                
                <img src="assets/images/layout/banner-013860.png?v=1" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
 
      {/* End Banner Top */}
      {/* <section className="crypto" data-aos="fade-up" data-aos-duration={1000}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="crypto__main">
                  <div className="flat-tabs">
                    <ul className="menu-tab">
                      <li className="active"><h6 className="fs-16">Crypto</h6></li>
                      <li><h6 className="fs-16">DeFi</h6></li>
                      <li><h6 className="fs-16">BSC</h6></li>
                      <li><h6 className="fs-16">NFT</h6></li>
                      <li><h6 className="fs-16">Metaverse</h6></li>
                      <li><h6 className="fs-16">Polkadot</h6></li>
                      <li><h6 className="fs-16">Solana</h6></li>
                      <li><h6 className="fs-16">Opensea</h6></li>
                      <li><h6 className="fs-16">Makersplace</h6></li>
                    </ul>
                    <div className="content-tab">
                      <div className="content-inner">
                        <div className="crypto-box">
                          <div className="top">
                            <a href="#"><span className="icon-btc"><span className="path1" /><span className="path2" /></span>
                              <span>Bitcoin</span>
                              <span className="unit">BTC/USD</span></a>
                          </div>
                          <h6 className="price">USD 46,168.95</h6>
                          <div className="bottom">
                            <p>36,641.20</p>
                            <p className="sale critical">-0.79%</p>
                          </div>
                        </div>
                        <div className="crypto-box active">
                          <div className="top">
                            <a href="#"><span className="icon-eth"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /></span><span>Ethereum</span>
                              <span className="unit">ETH/USD</span></a>
                          </div>
                          <h6 className="price">USD $3,480.04</h6>
                          <div className="bottom">
                            <p>36,641.20</p>
                            <div className="sale success">+10.55%</div>
                          </div>
                        </div>
                        <div className="crypto-box">
                          <div className="top">
                            <a href="#"><span className="icon-tether"><span className="path1" /><span className="path2" /></span><span>Tether</span>
                              <span className="unit">USDT/USD</span></a>
                          </div>
                          <h6 className="price">USD 1.00</h6>
                          <div className="bottom">
                            <p>36,641.20</p>
                            <div className="sale critical">-0.01%%</div>
                          </div>
                        </div>
                        <div className="crypto-box">
                          <div className="top">
                            <a href="#"><span className="icon-bnb"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></span><span>BNB</span> <span className="unit">BNB/USD</span></a>
                          </div>
                          <h6 className="price">USD 443.56</h6>
                          <div className="bottom">
                            <p>36,641.20</p>
                            <div className="sale critical">-1.24%</div>
                          </div>
                        </div>
                      </div>
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      <div className="body header-fixed home-2 markets">
        <Market2 />
      </div>



      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            paddingBlock: "2rem",
            width: "80%",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.5rem 1rem",
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              marginBottom: "0.5rem",
            }}
          >
            <h6 style={{ color: "#0e0637" }}>Mined Coin</h6>
            <p>0</p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.5rem 1rem",
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              marginBottom: "0.5rem",
            }}
          >
            <h6 style={{ color: "#0e0637" }}>Market cap</h6>
            <p>$0</p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.5rem 1rem",
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              marginBottom: "0.5rem",
            }}
          >
            <h6 style={{ color: "#0e0637" }}>Circulating supply</h6>
            <p>0</p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.5rem 1rem",
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              marginBottom: "0.5rem",
            }}
          >
            <h6 style={{ color: "#0e0637" }}>Max Supply </h6>
            <p>1,000,000,000</p>
          </div>
        </Box>
      </Box>


      

      <section className="work">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text center">
                <h3 className="heading">How It Works</h3>
                <p className="fs-20 desc">
                  RisingCoin is a non-volatile cryptocurrency that never falls.
                  Our crypto is developed to bring continous appreciation
                  overtime.
                </p>
              </div>
              <div className="work__main">
                <div className="work-box">
                  <div
                    className="image"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src="assets/images/icon/AssetWallet.png" alt="" style={{width:"50px", height:"50px"}} />
                  </div>
                  <div className="content">
                    <p className="step">Step 1</p>
                    <a href="#" className="title">
                      Connect Wallet
                    </a>
                    <p className="text">
                      Connect your wallet on either our official website or on
                      BSC Scan
                    </p>
                  </div>
                  <img
                    className="line"
                    src="assets/images/icon/connect-line.png"
                    alt=""
                  />
                </div>
                <div className="work-box">
                  <div
                    className="image"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src="assets/images/icon/AssetImport.png" alt="" style={{width:"50px", height:"50px"}} />
                  </div>
                  <div className="content">
                    <p className="step">Step 2</p>
                    <a href="#" className="title">
                      Import Coin
                    </a>
                    <p className="text">
                      Copy Rising Coin contract address. Go to the wallet you
                      connected. Scrol down to import token. Click to open and
                      paste the contract address. Save.
                    </p>
                  </div>
                  <img
                    className="line"
                    src="assets/images/icon/connect-line.png"
                    alt=""
                  />
                </div>
                <div className="work-box">
                  <div
                    className="image"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src="assets/images/icon/AssetMine.png" alt="" style={{width:"50px", height:"50px"}} />
                  </div>
                  <div className="content">
                    <p className="step">Step 3</p>
                    <a href="https://accounts.risingcoin.xyz/user/register" className="title" target="_blank">
                      Register
                    </a>
                    <p className="text">
                      You can mine Rising Coin for free ever 24 hour, but be
                      sure you have BNB to pay for gas fee. Click of Mine coin.
                      Wait for few minutes. Check your wallet.
                    </p>
                  </div>
                  <img
                    className="line"
                    src="assets/images/icon/connect-line.png"
                    alt=""
                  />
                </div>
                <div className="work-box">
                  <div
                    className="image"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src="assets/images/icon/AssetTrade.png" alt="" style={{width:"50px", height:"50px"}} />
                  </div>
                  <div className="content">
                    <p className="step">Step 4</p>
                    <a href="#" className="title">
                      Trade Rising Coin
                    </a>
                    <p className="text">
                      Connect wallet and check you have Rising Coin. Go to
                      Trade. To buy input the amount you want. To sell. Click on
                      sell, input the amount you want to sell. Your orders will
                      be placed on order book and waiting for match order.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="about_image">
                <div className="swiper img-swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <img
                        className="img-main"
                        src="assets/images/layout/about-h1.png"
                        alt=""
                      />
                    </div>
                    <div className="swiper-slide">
                      <img
                        className="img-main"
                        src="assets/images/layout/about-h1.png"
                        alt=""
                      />
                    </div>
                    <div className="swiper-slide">
                      <img
                        className="img-main"
                        src="assets/images/layout/about-h1.png"
                        alt=""
                      />
                    </div>
                    <div className="swiper-slide">
                      <img
                        className="img-main"
                        src="assets/images/layout/about-h1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="swiper-pagination" />
                </div>
                <img
                  className="icon icon-1"
                  src="assets/images/icon/icon-01.png"
                  alt=""
                />
                <img
                  className="icon icon-2"
                  src="assets/images/icon/icon-02.png"
                  alt=""
                />
                <img
                  className="icon icon-3"
                  src="assets/images/icon/icon-03.png"
                  alt=""
                />
                <img
                  className="icon icon-4"
                  src="assets/images/icon/icon-04.png"
                  alt=""
                />
                <img
                  className="icon icon-5"
                  src="assets/images/icon/icon-05.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div
                className="about__content"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <h3 className="heading">How to Buy RisingCoin</h3>
                <p className="fs-20 decs">
                  You can buy or sell Rising Coin using any Dex or Cex Exchange
                  which has listed Rising Coin for trading. You can also used
                  our Peer to peer Decentralize Exchange.
                </p>
                <ul className="list">
                  <li>
                    <h6 className="title">
                      <span className="icon-check" />
                      Connect your wallet.
                    </h6>
                    <p className="text">
                      Go to trade. Place Coin on sell or you input amount and
                      click on buy.
                    </p>
                  </li>
                  <li>
                    <h6 className="title">
                      <span className="icon-check" />
                      Instant trade
                    </h6>
                    <p className="text">
                      On other Dex and Cex. Instant trade takes place but the
                      price there is base on rise and fall. But using our Dex
                      peer to peer the price of Rising Coin is guaranteed not to
                      fall by a cent.
                    </p>
                  </li>

                  <li>
                    <h6 className="title">
                      <span className="icon-check" />
                      Liquidity
                    </h6>
                    <p className="text">
                      Anyone on Dex can provide liquidity on Rising Coin example
                      on Pancake swap. When you provide liquidity you hold the
                      LP token which you can remove at anytime in exchange of
                      the original token (Rising Coin).
                    </p>
                  </li>
                </ul>
                <a
                  href="#"
                  className="btn-action"
                  style={{ backgroundColor: "#0e0637" }}
                >
                  Explore More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="download">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div
                className="download__content"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <h3 className="heading">
                  Never lose a cent holding a cryptocurrency.
                </h3>
                <p className="fs-20 decs">
                  When you hold Rising Coin you are having an asset that
                  continue to only appreciate in value.
                </p>
                <ul className="list">
                  <li>
                    <h6 className="title">
                      <span className="icon-check" />
                      Buy, sell and trade on listed exchange.
                    </h6>
                    <p className="text">
                      Manage your holdings from your mobile device
                    </p>
                  </li>
                  <li>
                    <h6 className="title">
                      <span className="icon-check" />
                      Be in control of your asset
                    </h6>
                    <p className="text">
                      First ever crypto that only grows in value without ever
                      depreciating.
                    </p>
                  </li>
                </ul>
                <div className="group-button">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.risingcoin.android&hl=en&gl=US"
                    target="_blank"
                  >
                    <img src="assets/images/icon/googleplay.png" alt="" />
                  </a>
                  <a href="#">
                    <img src="assets/images/icon/appstore.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="download__image">
                <div className="button">Scan To Download</div>
                <img src="assets/images/layout/download3860.png?v=1" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "5rem",
          // paddingInline: { xs: "1rem", sm: "10rem" },
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            marginBottom: "0.5rem",
            color: "#23262f",
            fontWeight: "700",
          }}
        >
          Coin Distribution
        </Typography>
        <Typography
          sx={{ fontSize: "18px", marginBottom: "2.5rem", textAlign: "center" }}
        >
          {" "}
          Rising Coin tokenomic shows how Coin will be distributed.
        </Typography>

        <Box
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <img
            src={tokenomics}
            style={{ width: "70%", height: "70%", borderRadius: "15px" }}
          />
        </Box>

        <Box
          sx={{ display: { xs: "block", sm: "none", padding: "10px 10px" } }}
        >
          <img
            src={tokenomics}
            style={{ width: "100%", height: "100%", borderRadius: "15px" }}
          />
        </Box>
      </Box>
      <section className="testimonials">
        <div className="container">
          <h3 className="heading">Everyone loves RisingCoin</h3>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Swipers />
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Swipers2 />
          </Box>
        </div>
      </section>

    

      <section className="section-sale" style={{ backgroundColor: "#0e0637", marginTop:"3rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="block-text">
                <h4 className="heading">Earn in Rising Coin up to %140. </h4>
                <p className="desc">
                  Mine coin, verify users, refer, buy and sell, contribute to
                  development.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="button">
                <a
                  href="https://play.google.com/store/apps/details?id=com.risingcoin.android&hl=en&gl=US"
                  target="_blank"
                >
                  Start Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Swiper JS */}

 
      <Footer />
    </div>
  );
}

export default Home;
