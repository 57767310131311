import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import Faq from "./Faq";
import nft from "../images/nft.jpg";   
import trading from "../images/trading.jpg";   
import c from "../images/c.jpg";   
import d from "../images/d.jpg";   

         

import e from "../images/e.jpg";                                
import f from "../images/f.jpg";                              





function Community() {
  return (
    <div>
  
      <div>
        <main className="flex flex-col flex-1 justify-center" id="main">
          <section className="relative bg-light text-copy text-center" style={{}}>
            <div className="mx-auto px-4 md:px-6 xl:px-8 2xl:px-12 prose-sm md:prose lg:prose-xl relative z-10 pt-14 md:pt-28 pb-14 md:pb-28 max-w-5xl">
              <h1 className="max-w-4xl mx-auto">Join the Rising Coin community</h1>
              <p className="lead mx-auto md:px-6 max-w-3xl">
                Rising Coin is a 100% open-source project created to enable a
                decentralized web and better society. Anyone can contribute.
              </p>
<a href="https://t.me/risingcoingroup"  target="_blank">
<Button variant="contained" sx={{borderRadius:"30px", padding:"0.7rem 1.5rem", marginTop:"1rem", backgroundColor:"#0e0637", "&:hover":{backgroundColor:"#0e0637"}}}> Join Community</Button>
</a>
              
            </div>
          </section>
         
        </main>
      </div>





      <div className="min-h-screen flex flex-col overflow-x-hidden">
              <div className="relative h-18" />
            
              <main className="flex flex-col flex-1 justify-center" id="main">
          
             
                <section className="relative bg-light text-copy">

                <Box sx={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", marginTop:"5rem"}}>
                <Box sx={{fontSize:"20px", fontWeight:"700", }}>What is news this week</Box>
              </Box>
                  <div className="mx-auto max-w-7xl px-4 md:px-6 xl:px-8 2xl:px-12 prose-sm md:prose lg:prose-xl pt-18 md:pt-24 pb-18 md:pb-24">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 auto-rows-fr">

                 





                      <div className="card relative w-full h-full outline-0 overflow-hidden flex transition duration-200 ease-in-out not-prose flex-col rounded-md shadow-small shadow-black/20 bg-light text-copy">
                        <div className="w-full aspect-2">
                          <img
                              src={trading}
                            alt=""
                            loading="lazy"
                            className="block w-full m-0 object-center object-cover aspect-2"
                          />
                        </div>
                        <div className="py-4 md:pt-4 md:pb-6 px-4 md:px-6">
                          <a
                            className="relative inline-block mb-2 z-20 "
                            href="https://link.medium.com/cvQbqg6lJyb"
                            target="_blank"
                            style={{color:"#0e0637"}}
                          >
                            Rising Coin
                          </a>
                          <a
                            className="block after:absolute after:inset-0 after:z-10 outline-0 no-underline after:cursor-pointer"
                            href="https://link.medium.com/cvQbqg6lJyb"
                          >
                            <p className="h5 !mb-2">
                            Trading Cryptocurrency: Types of Trades You Can Make
                            </p>
                          </a>
                          <p className="line-clamp-4">
                          There are hundreds of cryptocurrencies, each with its own market cap and fluctuating price—and then there are the different types of trades you can make within that market. How do you know which type to choose? It all depends on your financial situation and your goals as an investor.
                          </p>
                        </div>
                      </div>
                      <div className="card relative w-full h-full outline-0 overflow-hidden flex transition duration-200 ease-in-out not-prose flex-col rounded-md shadow-small shadow-black/20 bg-light text-copy">
                        <div className="w-full aspect-2">
                          <img
                              src={c}
                            alt=""
                            loading="lazy"
                            className="block w-full m-0 object-center object-cover aspect-2"
                          />
                        </div>
                        <div className="py-4 md:pt-4 md:pb-6 px-4 md:px-6">
                          <a
                            className="relative inline-block mb-2 z-20 "
                            href="https://link.medium.com/fXcmxJymJyb"
                            target="_blank"
                            style={{color:"#0e0637"}}
                          >
                            Rising Coin
                          </a>
                          <a
                            className="block after:absolute after:inset-0 after:z-10 outline-0 no-underline after:cursor-pointer"
                            href="https://link.medium.com/fXcmxJymJyb"
                            target="_blank"
                          >
                            <p className="h5 !mb-2">
                            DiFi and Dapps: What's the Difference?
                            </p>
                          </a>
                          <p className="line-clamp-4">
                          Are Decentralized Applications (DApps) and decentralized FinTech applications (DiFi) both the same thing, or do they differ in their approach?

Decentralized applications (DApps) are built on a decentralized network, such as a blockchain. They're open source, and anyone can contribute to their development. Decentralized finance (DiFi) applications are also built on decentralized networks. However, they focus on financial transactions and services.
                          </p>
                        </div>
                      </div>
                      <div className="card relative w-full h-full outline-0 overflow-hidden flex transition duration-200 ease-in-out not-prose flex-col rounded-md shadow-small shadow-black/20 bg-light text-copy">
                        <div className="w-full aspect-2">
                          <img
                              src={d}
                            alt=""
                            loading="lazy"
                            className="block w-full m-0 object-center object-cover aspect-2"
                          />
                        </div>
                        <div className="py-4 md:pt-4 md:pb-6 px-4 md:px-6">
                          <a
                            className="relative inline-block mb-2 z-20 "
                            href="https://medium.com/@risingcoin_/all-about-blockchain-technology-805d5b4492d3"
                            target="_blank"
                            style={{color:"#0e0637"}}
                          >
                            Rising Coin
                          </a>
                          <a
                            className="block after:absolute after:inset-0 after:z-10 outline-0 no-underline after:cursor-pointer"
                            href="https://medium.com/@risingcoin_/all-about-blockchain-technology-805d5b4492d3"
                          >
                            <p className="h5 !mb-2">
                            All About Blockchain Technology
                            </p>
                          </a>
                          <p className="line-clamp-4">
                    
                          Blockchain is a security network that comes in the form of a series of blocks. it’s a distributed database and a ledger shared among the nodes of a computer that provides an unhackable security system for cryptocurrency top hits attend at stores information electronically in digital format and maintains a secure and decentralized record of transactions.
                          </p>
                        </div>
                      </div>
                      <div className="card relative w-full h-full outline-0 overflow-hidden flex transition duration-200 ease-in-out not-prose flex-col rounded-md shadow-small shadow-black/20 bg-light text-copy">
                        <div className="w-full aspect-2">
                          <img
                               src={e}
                            alt=""
                            loading="lazy"
                            className="block w-full m-0 object-center object-cover aspect-2"
                          />
                        </div>
                        <div className="py-4 md:pt-4 md:pb-6 px-4 md:px-6">
                          <a
                            className="relative inline-block mb-2 z-20 "
                            href="https://link.medium.com/nZQltKP9oyb"
                            target="_blank"
                            style={{color:"#0e0637"}}
                          >
                            Rising Coin
                          </a>
                          <a
                            className="block after:absolute after:inset-0 after:z-10 outline-0 no-underline after:cursor-pointer"
                            href="https://link.medium.com/nZQltKP9oyb"
                            target="_blank"
                          >
                            <p className="h5 !mb-2">
                            Cryptocurrencies: Volatile, Non-Volatile and Stable Coins How they work
                            </p>
                          </a>
                          <p className="line-clamp-4">
                          What determines whether a coin will be volatile or not? Why do some coins remain stable while others continuously fluctuate in value? When investing in cryptocurrencies, it’s important to know the answers to these questions in order to understand what type of coin you are investing in.
                          {" "}
                          </p>
                        </div>
                      </div>
                      <div className="card relative w-full h-full outline-0 overflow-hidden flex transition duration-200 ease-in-out not-prose flex-col rounded-md shadow-small shadow-black/20 bg-light text-copy">
                        <div className="w-full aspect-2">
                          <img
                              src={f}
                            alt=""
                            loading="lazy"
                            className="block w-full m-0 object-center object-cover aspect-2"
                          />
                        </div>
                        <div className="py-4 md:pt-4 md:pb-6 px-4 md:px-6">
                          <a
                            className="relative inline-block mb-2 z-20 "
                            href="https://link.medium.com/WwSvnEanJyb"
                            target="_blank"
                            style={{color:"#0e0637"}}
                          >
                            Rising Coin
                          </a>
                          <a
                            className="block after:absolute after:inset-0 after:z-10 outline-0 no-underline after:cursor-pointer"
                            href="https://link.medium.com/WwSvnEanJyb"
                            target="_blank"
                          >
                            <p className="h5 !mb-2">
                            Airdrop: The free way to get cryptocurrency
                            </p>
                          </a>
                          <p className="line-clamp-4">
                          How do they work
Airdrops are a marketing tool used by ICOs and blockchain projects to promote awareness and adoption of their coin or token. By giving away free coins or tokens, airdrops can generate buzz and interest in a project. Airdrops can also be used to reward existing holders of a particular coin or token. For example, a project may airdrop tokens to all holders of Bitcoin as a way to incentivize people to hold onto their Bitcoin.
                          </p>
                        </div>
                      </div>
                 
               
                    </div>
               
                  </div>
                </section>
              </main>
             
            </div>



<Faq />

<Footer/>
    </div>
  );
}

export default Community;
