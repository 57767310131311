import React from 'react'
import Navbar from "../components/Navbar"
import { Link } from 'react-router-dom'
import Market2 from "./Market2"
import Market3 from "./Market3"
import { Box } from '@mui/material'


function Market() {
  return (
    <div className="body header-fixed home-2 markets">

   
    <section className="banner">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="banner__content">
              <h2 className="title">Today’s Cryptocurrency prices</h2>
              
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="banner__image">
              <img src="assets/images/layout/banner-043860.png?v=1" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>
 
<Market2/>



<Market3/>


     <footer className="footer border-top">
      <div className="container">
        <div className="footer__main">
          <div className="row">
            <div className="col-xl-4 col-md-8">
              <div className="info">
                <Link to="index-2.html" className="logo">
                  <img src="assets/images/logo/log-footer.png" alt=""/>
                </Link>
                <h6>Join the Community 🤙</h6>
          
        
        <ul className="list-social">
                  <li>
                    <Link to="#"><span className="icon-facebook-f"></span> Facebook Community</Link>
                  </li>
                  <li>
                    <Link to="#"><span className="icon-instagram"></span> Instagram Community</Link>
                  </li>
                  <li>
                    <Link to="#"><span className="icon-youtube"></span> Youtube Community</Link>
                  </li>
                  <li>
                    <Link to="#"><span className="icon-twitter"></span> Twitter Community</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="widget-link s1">
                <h6 className="title">PRODUCTS</h6>
                <ul>
                  <li><Link to="spot.html">Spot</Link></li>
                  <li><Link to="#">Inverse Perpetual</Link></li>
                  <li><Link to="#">USDT Perpetual</Link></li>
                  <li><Link to="exchange.html">Exchange</Link></li>
                  <li><Link to="#">Launchpad</Link></li>
                  <li><Link to="#">Binance Pay</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="widget-link s2">
                <h6 className="title">SERVICES</h6>
                <ul>
                  <li><Link to="buy-crypto-select.html">Buy Crypto</Link></li>
                  <li><Link to="markets.html">Markets</Link></li>
                  <li><Link to="#">Tranding Fee</Link></li>
                  <li><Link to="#">Affiliate Program</Link></li>
                  <li><Link to="#">Referral Program</Link></li>
                  <li><Link to="#">API</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="widget-link s3">
                <h6 className="title">SUPPORT</h6>
                <ul>
                  <li><Link to="#">RisingCoin Learn</Link></li>
                  <li><Link to="#">Help Center</Link></li>
                  <li><Link to="user-profile.html">User Feedback</Link></li>
                  <li><Link to="#">Submit a request</Link></li>
                  <li><Link to="#">API Documentation</Link></li>
                  <li><Link to="#">Trading Rules</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="widget-link s4">
                <h6 className="title">ABOUT US</h6>
                <ul>
                  <li><Link to="#">About RisingCoin</Link></li>
                  <li><Link to="#">Authenticity Check</Link></li>
                  <li><Link to="#">Careers</Link></li>
                  <li><Link to="#">Business Contacts</Link></li>
                  <li><Link to="blog-default.html">Blog</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg">
        <div className="footer__bottom">
          <p>Copyright © 2023 RisingCoin</p>
          <ul className="list-social">
            <li>
              <Link to="#"><i className="fa-brands fa-facebook"></i></Link>
            </li>
            <li>
              <Link to="#"><i className="fa-brands fa-twitter"></i></Link>
            </li>
            <li>
              <Link to="#"><i className="fa-brands fa-instagram"></i></Link>
            </li>
            <li>
              <Link to="#"><i className="fa-brands fa-linkedin"></i></Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>

    <script src="app/js/aos.js"></script>
    <script src="app/js/jquery.min.js"></script>
    <script src="app/js/jquery.easing.js"></script>
    <script src="app/js/popper.min.js"></script>
    <script src="app/js/bootstrap.min.js"></script>
    <script src="app/js/app.js"></script>
    <script src="app/js/jquery.peity.min.js"></script>
    <script src="app/js/Chart.bundle.min.js"></script>
    <script src="app/js/apexcharts.js"></script>
    <script src="app/js/switchmode.js"></script>
    <script src="app/js/jquery.magnific-popup.min.js"></script>

    <script src="app/js/chart.js"></script>
    <script src="boxicons%402.1.2/dist/boxicons.js"></script>

   
    <script src="app/js/swiper-bundle.min.js"></script>

    <script src="app/js/swiper.js"></script>
  </div>
  )
}

export default Market