import React from 'react'
import Ticker from 'react-ticker'
import { Box, Typography } from '@mui/material'

function Tickers (){

return(
    <Ticker>
    {({ index }) => (
        <>
            <Box sx={{backgroundColor:"#f2f3f5", height:"5vh", width:"100%", margin:"0px 0px", border:"3px solid #fafafa", display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center" }}>
<Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center"}}>
  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
  <Typography sx={{fontSize:"12px"}}>BTC/USDT</Typography>
  <Typography sx={{fontSize:"12px", color:"green"}}>3%</Typography>
  <Typography sx={{fontSize:"12px"}}>$2500</Typography>
  </Box>
  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
  <Typography sx={{fontSize:"12px"}}>ADA/USDT</Typography>
  <Typography sx={{fontSize:"12px", color:"green"}}>3%</Typography>
  <Typography sx={{fontSize:"12px"}}>$2500</Typography>
  </Box>
  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
  <Typography sx={{fontSize:"12px"}}>ETH/USDT</Typography>
  <Typography sx={{fontSize:"12px", color:"red"}}>-2%</Typography>
  <Typography sx={{fontSize:"12px"}}>$2500</Typography>
  </Box>
  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
  <Typography sx={{fontSize:"12px"}}>BNB/USDT</Typography>
  <Typography sx={{fontSize:"12px", color:"green"}}>1%</Typography>
  <Typography sx={{fontSize:"12px"}}>$2500</Typography>
  </Box>
  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
  <Typography sx={{fontSize:"12px"}}>SOL/USDT</Typography>
  <Typography sx={{fontSize:"12px", color:"red"}}>-5%</Typography>
  <Typography sx={{fontSize:"12px"}}>$2500</Typography>
  </Box>
  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
  <Typography sx={{fontSize:"12px"}}>SOL/USDT</Typography>
  <Typography sx={{fontSize:"12px", color:"red"}}>-5%</Typography>
  <Typography sx={{fontSize:"12px"}}>$2500</Typography>
  </Box>

  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
  <Typography sx={{fontSize:"12px"}}>SOL/USDT</Typography>
  <Typography sx={{fontSize:"12px", color:"red"}}>-5%</Typography>
  <Typography sx={{fontSize:"12px"}}>$2500</Typography>
  </Box>

  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
  <Typography sx={{fontSize:"12px"}}>SOL/USDT</Typography>
  <Typography sx={{fontSize:"12px", color:"red"}}>-5%</Typography>
  <Typography sx={{fontSize:"12px"}}>$2500</Typography>
  </Box>
</Box>
</Box>
        </>
    )}
</Ticker>
)




}
export default Tickers 