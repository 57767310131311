import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import Navbar from '../src/components/Navbar';
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Buffer } from 'buffer';
import DataContex from './pages/DataContex';





const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

window.Buffer = window.Buffer || Buffer;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <Web3ReactProvider getLibrary={getLibrary}>
   
 
    < DataContex>
    <div>
    <Navbar/>
    <App /> 
    </div>
 
    </DataContex>

    </Web3ReactProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();