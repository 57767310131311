import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import Token from "../images/rsc.png";
import { ethers } from "ethers";
import Navbar from "../components/Navbar";

import { Alert, Box } from "@mui/material";
import Footer from "./Footer";

function Presale() {
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("0");
  const [value2, setValue2] = useState("0");
  const [balances, setBalance] = useState();
  const [success, setSuccess] = useState();

  const [mobile, setmobile] = useState(false);
  const { library, chainId, account, activate, deactivate, active } =
    useWeb3React();

  // useEffect( () => {
  // balance()
  // }

  // , [])

  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
  };

  const balance = async () => {
    try {
      if (active) {
        const signer = library.getSigner();
        const Balance = await signer.getBalance();
        const mybalance = ethers.utils.formatEther(Balance);
        console.log(mybalance);
        setBalance(mybalance);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const disconnect = () => {
    refreshState();
    deactivate();
  };

  async function execute() {
    setError2(false);
    setError(false);
    setSuccess(false);

    if (active) {
      const contractAddressToken = "0x82FDcCbB1DF3e3b4a7B70A08122Ed9c7Cc309754"; 

      const abi2 = [
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_rate",
              "type": "uint256"
            },
            {
              "internalType": "address payable",
              "name": "_wallet",
              "type": "address"
            },
            {
              "internalType": "contract IERC20",
              "name": "_token",
              "type": "address"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "purchaser",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "TokenPurchase",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "address",
              "name": "_from",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            }
          ],
          "name": "TransferReceived",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "balance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "buyTokens",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "rate",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "token",
          "outputs": [
            {
              "internalType": "contract IERC20",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "wallet",
          "outputs": [
            {
              "internalType": "address payable",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "weiRaised",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ];
      const signer = library.getSigner();
      const address = await signer.getAddress();

      const contractToken = new ethers.Contract(
        contractAddressToken,
        abi2,
        signer
      );
      try {
        const Amount = ethers.utils.parseUnits(value.toString(), 18);

        console.log(value);

        // await API.graphql({ query:createSpendingAddress,
        //   variables: { input:obj} ,
        // }

        // );

        const buyToken = await contractToken.buyTokens({
          value: ethers.utils.parseUnits(value2.toString(), "ether"),
          gasLimit: 500000,
        });
        console.log(buyToken);

        setSuccess(true);

        //  console.log(name)
        //  console.log(approve)
        //     console.log(depositToken)
        // console.log(depositToken)
      } catch (error) {
        setError2(true);
        setMessage(error.message);
        console.log(error);
      }
    } else {
      setError(true);
    }
  }

  return (
    <div>

     
      <div className="banner2" style={{ width: "100vw", height: "100vh" }}>



        <div className="flex1 flex1-col justify-between min-h-screen py-4">
          <div className="container1 mx-auto  px-4 py-4">
            <div
              style={{ backgroundColor: "#fff" }}
              className="grid gap-y-2 bg-white dark:bg-[#000000] w-lg max-w-full rounded-2xl py-6 px-6 shadow-2xl mx-auto "
            >
              <h2
                className="mb-1 text-center font-extrabold text-4xl"
                style={{ color: "#23262f", fontSize: "1.4rem", display:"none" }}
              >
                {" "}
                JOIN RISING COIN PRESALE{" "}
              </h2>
              <div
                style={{ color: "#777e90", fontSize: "0.8rem", display:"hidden" }}
                className="text-center font-bold mb-1"
              >
                {" "}
                RISING COIN SWAP{" "}
                <span style={{ color: "#777e90", fontSize: "4rem", display:"none" }}>
                
                </span>{" "}
              </div>

              {/**/}

            <Box  sx={{height:"3rem"}}></Box>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#000",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=024"
                    width={30}
                    height={30}
                    className="bg-white rounded-full w-7 h-7"
                    loading="lazy"
                    style={{ marginRight: "1rem" }}
                  />{" "}
                  <span>BNB</span>
                  <input
                    style={{
                      border: "0px solid #fff",
                      color: "#000",
                      marginLeft: "2rem",
                      width: "100%",
                    }}
                    type="text"
                    placeholder="0"
                    onChange={(e) => {
                      setValue2(e.target.value);
                      setValue(e.target.value / 0.000076);
                    }}
                  />
                </div>

                {/**/}
                {/**/}
                {/**/}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBlock: "1.5rem",
                }}
              >
                <div style={{ width: "90%", border: "solid 1px #000" }}></div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#000",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Token}
                    alt="RSC Coin"
                    width={30}
                    height={30}
                    className="bg-white rounded-full w-7 h-7"
                    loading="lazy"
                    style={{ marginRight: "1rem" }}
                  />{" "}
                  <span>RSC</span>
                  <input
                    style={{
                      border: "0px solid #fff",
                      color: "#000",
                      marginLeft: "2rem",
                      width: "100%",
                    }}
                    type="text"
                    placeholder="0"
                    value={value}
                  />
                </div>

                {/**/}
                {/**/}
                {/**/}
              </div>

              {error && <Alert severity="info"> connet your wallet!!</Alert>}
              {error2 && (
                <Alert severity="error">
                  {" "}
                  error occured, check your connected chain!!
                </Alert>
              )}
              {success && (
                <Alert severity="success">
                  You have successfully bought {value} RSC
                </Alert>
              )}
              <button
                className="button-submit text-black-800 dark:text-black-600 bg-blue-700 hover:bg-blue-400 w-full p-4 font-bold rounded-4xl"
                style={{
                  color: "#fff",
                  marginTop: "4rem",
                  backgroundColor: "#0e0637",
                }}
             
              >
                SWAP
              </button>
            </div>
          </div>
          <div className="pb-5 w-full text-center" />
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Presale;
