import React, {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { Box, Button } from "@mui/material";

function Faq() {
  const [show, setshow] = useState(false)
  const [show2, setshow2] = useState(false)
  const [show3, setshow3] = useState(false)
  const [show4, setshow4] = useState(false)
  const [show5, setshow5] = useState(false)
  const [show6, setshow6] = useState(false)
  const [show7, setshow7] = useState(false)
  const [show8, setshow8] = useState(false)
  const [show9, setshow9] = useState(false)
  const [show10, setshow10] = useState(false)
  const [show11, setshow11] = useState(false)

  return (
    <div>  
    
        
      <section className="relative bg-light text-copy">
  
        <div className="mx-auto max-w-7xl px-4 md:px-6 xl:px-8 2xl:px-12 prose-sm md:prose lg:prose-xl pt-18 md:pt-24 pb-18 md:pb-24">
          <div className="max-w-3xl mx-auto">
            <div className="mb-12 card relative w-full h-full outline-0 overflow-hidden flex transition duration-200 ease-in-out not-prose flex-col rounded-md bg-medium text-copy">
              <div className="py-4 md:pt-4 md:pb-6 px-4 md:px-6">
                <div className="block outline-0 no-underline pointer-events-auto">
                  <p className="h5 !mb-2">Disclaimer</p>
                </div>
                <p>
                  Rising coin is a work in progress. The following FAQs will
                  continue to be updated as the Rising coin platform evolves. The
                  exact structures and technologies to be included at genesis
                  are not finalized and are liable to change with continued
                  research.
                </p>
              </div>
            </div>
      
   
            <div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
   
   
    style={{color:"#0e0637"}}
  >
    1. How is it possible for a coin to rise without falling?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow(!show)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        RisingCoin is designed to appreciate in value over time through its unique price determination formula, which takes into account the transaction fees and the total coin supply. The algorithm is programmed to ensure that the coin's price only goes up, making it a stable and predictable investment.
        </p>
     
      
      </div>
   
    </div>}
</div>


  



  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
 2. What is the maximum supply of RisingCoin?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow2(!show2)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show2 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
      
The maximum supply of RisingCoin is 1 billion units, and no new coins will be created after this limit is reached. At the halfway point, a halving event will occur, reducing the block rewards and slowing down the coin's issuance.
   </p>
     
      
      </div>
   
    </div>}
</div>



  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
3. How can I acquire RisingCoin?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow3(!show3)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show3 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        RisingCoin can be acquired through mining, airdrops, peer-to-peer exchanges, and listed exchanges. Once you have acquired RisingCoin, it is essential to store it in a secure wallet that you trust.
        </p>
     
      
      </div>
   
    </div>}
</div>


  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
   4. What is the difference between ICO price and presale price?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow4(!show4)}}
      >
        <path
         style={{color:"#0e0637"}}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    </span>
  </button>

  { show4 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        The ICO price is the initial offering price of a cryptocurrency during its initial coin offering (ICO) phase. The presale price is a discounted price offered to early investors before the ICO launch. In the case of RisingCoin, the presale is being conducted on the Binance Smart Chain at a price of $0.025.
        </p>
     
      
      </div>
   
    </div>}
</div>


  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
5. What is the Road Map for RisingCoin?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow5(!show5)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show5 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        The Road Map for RisingCoin includes several milestones such as Coin Development, Coin Mining Phase, Deployment on Dex Blockchain (BSC), Holding Coin on a Dex Wallet, Coin Listing, and Trading on Dex/Cex Exchanges. The ultimate goal is to build the RisingCoin Blockchain and create a variety of use cases for the coin.
        </p>
     
      
      </div>
   
    </div>}
</div>


  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >

6. How can I use RisingCoin?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow6(!show6)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show6 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        RisingCoin has several use cases, including using it for trading on Dex classified marketplaces, Dex Newsified, Dex social, Dex Live platform, and as a means of payment. You can also stake your coins to earn rewards and use it for value storage.
        </p>
     
      
      </div>
   
    </div>}
</div>


  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
7. Is RisingCoin secure?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow7(!show7)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show7 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        RisingCoin offers robust security features, including account verification and two-factor authentication, to protect user accounts from unauthorized access. Additionally, the coin is designed to prevent price depreciation, making it a stable investment option.
        </p>
     
      
      </div>
   
    </div>}
</div>


  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
     8. Is Rising Coin a coin or a token? 
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow8(!show8)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show8 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
 

      Rising Coin is currently considered a token because it was initially built on the Binance Smart Chain (BSC) blockchain. However, as per the Rising Coin roadmap, the development of the Rising Coin blockchain is underway, which means that it will eventually become a coin.

Tokens and coins are often used interchangeably, but technically, there is a difference between the two. Coins are standalone digital currencies with their own blockchain, while tokens are built on top of an existing blockchain, such as Ethereum, BSC, or others.

In the case of Rising Coin, it is currently a token on the BSC blockchain, but as soon as the development of the Rising Coin blockchain is complete, it will become a standalone coin.

        </p>
     
      
      </div>
   
    </div>}
</div>


  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
  9. Can I trade RisingCoin on CEX exchanges?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow9(!show9)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show9 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        Yes, RisingCoin can be traded on both Dex and Cex exchanges when it is listed, this will provide users with multiple options for buying and selling the coin.
        </p>
     
      
      </div>
   
    </div>}
</div>


  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
10. What is the future of RisingCoin?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow10(!show10)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show10 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        The future of RisingCoin is dependent on the success of its development roadmap and the adoption of its use cases. With a stable price, robust security features, and a dedicated development team, RisingCoin has the potential to become a popular cryptocurrency in the blockchain space.

        </p>
     
      
      </div>
   
    </div>}
</div>
  
<div>
  <button
    className="relative w-full text-left py-5 pr-14  font-bold cursor-pointer group transition"
    id="ideb1owj"
    aria-expanded="false"
    style={{color:"#0e0637"}}
  >
11. Is Rising Coin listed yet?
    <span className="flex justify-center items-center absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 !m-0 will-change-transform transition-all duration-300  origin-center rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
        onClick={()=>{setshow11(!show11)}}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          style={{color:"#0e0637"}}
        />
      </svg>
    </span>
  </button>

  { show11 && 
   <div
      className=""
      role="region"

      style={{ opacity: 1, height: "auto" }}
    >
     
     

<div className="relative overflow-hidden">
        <p>
        No, the team are working to see it being listed on several exchange and market Cap 
        </p>
     
      
      </div>
   
    </div>}
</div>
   
   


        
        
          
        
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faq;









































