import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core';

import Modal from '../pages/Modal';

function Navbar() {
  const [openModal, setOpenModal] = useState(false);
  const [mobile, setmobile] = useState(false);
  const {
    library,
    chainId,
    account,
    activate,
    deactivate,
    active
  } = useWeb3React();




  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
 
  };

  const disconnect = () => {
    refreshState();
    deactivate();
  };


  return (
    <div style={{position:"sticky", top:"0px", zIndex:"500"}}>
    
     
           <header id="header_main" className="header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="header__body d-flex justify-content-between">
                  <div className="header__left">
                    <div className="logo">
                      <Link className="light" to="/">
                        <img src="assets/images/logo/logo5e1f.png?v=2" alt="" width={118} height={32} data-retina="assets/images/logo/logo@2x.png" data-width={118} data-height={32} />
                      </Link>
                      <Link className="dark" to="/">
                        <img src="assets/images/logo/logo-dark.png" alt="" width={118} height={32} data-retina="assets/images/logo/logo-dark@2x.png" data-width={118} data-height={32} />
                      </Link>
                    </div>
                    <div className="left__main">
                      <nav id="main-nav" className="main-nav">
                        <ul id="menu-primary-menu" className="menu">
                          <li className="menu-item-has-children ">
                            <Link to="/whitepaper" >White Paper </Link>
                          </li>

                       
                          <li className="menu-item-has-children">
                            <Link to="/education">Education</Link>
                          </li>
                          {/* <li className="menu-item-has-children">
                            <Link to="/trade">Trade</Link>
                          </li> */}
                          <li className="menu-item-has-children">
                            <Link to="/markets">Markets </Link>
                          </li>
                        
                          <li className="menu-item-has-children">
                            <Link to="/swap">Swap</Link>
                          </li>

                          <li className="menu-item-has-children">
                            <Link to="/stake">Stake</Link>
                          </li>

                          <li className="menu-item-has-children">
                            <Link to="/community">Community</Link>
                          </li>

                          <li className="menu-item-has-children">
                            <Link to="/roadmap">Roadmap</Link>
                          </li>
                        </ul>
                      </nav>
                      {/* /#main-nav */}
                    </div>
                  </div>
                  <div className="header__right">
                    {/* <div className="dropdown">
                      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"zz>
                        Assets
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link className="dropdown-item" to="#">Binance Visa Card</Link>
                        <Link className="dropdown-item" to="#">Crypto Loans</Link>
                        <Link className="dropdown-item" to="#">Binance Pay</Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Orders &amp; Trades
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <Link className="dropdown-item" to="#">Binance Convert</Link>
                        <Link className="dropdown-item" to="#">Spot</Link>
                        <Link className="dropdown-item" to="#">Margin</Link>
                        <Link className="dropdown-item" to="#">P2P</Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        EN/USD
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <Link to="javascript:void(0);" className="dropdown-item notify-item language" data-lang="en">
                          <img src="assets/images/flags/us.jpg" alt="user-image" className="me-1" height={12} />
                          <span className="align-middle">English</span>
                        </Link>
                        <Link to="javascript:void(0);" className="dropdown-item notify-item language" data-lang="sp">
                          <img src="assets/images/flags/spain.jpg" alt="user-image" className="me-1" height={12} />
                          <span className="align-middle">Spanish</span>
                        </Link>
                        <Link to="javascript:void(0);" className="dropdown-item notify-item language" data-lang="gr">
                          <img src="assets/images/flags/germany.jpg" alt="user-image" className="me-1" height={12} />
                          <span className="align-middle">German</span>
                        </Link>
                        <Link to="javascript:void(0);" className="dropdown-item notify-item language" data-lang="it">
                          <img src="assets/images/flags/italy.jpg" alt="user-image" className="me-1" height={12} />
                          <span className="align-middle">Italian</span>
                        </Link>
                        <Link to="javascript:void(0);" className="dropdown-item notify-item language" data-lang="ru">
                          <img src="assets/images/flags/russia.jpg" alt="user-image" className="me-1" height={12} />
                          <span className="align-middle">Russian</span>
                        </Link>
                      </div>
                    </div>
                    <div className="mode-switcher">
                      <Link className="sun" to="#" onclick="switchTheme()">
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.99993 11.182C9.7572 11.182 11.1818 9.75745 11.1818 8.00018C11.1818 6.24291 9.7572 4.81836 7.99993 4.81836C6.24266 4.81836 4.81812 6.24291 4.81812 8.00018C4.81812 9.75745 6.24266 11.182 7.99993 11.182Z" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 1V2.27273" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 13.7271V14.9998" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M3.04956 3.04932L3.9532 3.95295" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.0469 12.0469L12.9505 12.9505" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1 8H2.27273" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.7273 8H15" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M3.04956 12.9505L3.9532 12.0469" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.0469 3.95295L12.9505 3.04932" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Link>
                      <Link to="#" className="moon" onclick="switchTheme()">
                        <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.0089 8.97241C12.7855 9.64616 12.4491 10.2807 12.0107 10.8477C11.277 11.7966 10.2883 12.5169 9.1602 12.9244C8.03209 13.3319 6.81126 13.4097 5.64056 13.1486C4.46987 12.8876 3.39772 12.2986 2.54959 11.4504C1.70145 10.6023 1.1124 9.53013 0.851363 8.35944C0.590325 7.18874 0.668097 5.96791 1.07558 4.8398C1.48306 3.71169 2.2034 2.72296 3.1523 1.9893C3.71928 1.55094 4.35384 1.21447 5.02759 0.991088C4.69163 1.84583 4.54862 2.77147 4.61793 3.7009C4.72758 5.17128 5.36134 6.55346 6.40394 7.59606C7.44654 8.63866 8.82873 9.27242 10.2991 9.38207C11.2285 9.45138 12.1542 9.30837 13.0089 8.97241Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Link>
                    </div> */}
                    <div className="mobile-button"><span />
                    
                    </div>

                    {/* {!active && <div className="wallet" onClick={() => setOpenModal(true)} >
                      <a>Connect Wallet </a>
                    </div>}
                 
 
                   {active && <div className="wallet"   onClick={disconnect}>
                      <a>Disconnect Wallet </a>
                    </div>} */}

                  </div>
                </div> 
              </div>  
            </div>
          </div>


          
        </header>
    </div>
  )
}

export default Navbar