import React, { useState } from "react";
import whitepaper from "../images/RSCWhitepaper.pdf";

function WhitePaper() {
  const [show, setshow] = useState(false);
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {show && (
        <div style={{ width: "100vw", height: "100vh" }}>
          <embed type="text/html" src={whitepaper} width="100%" height="100%" />
        </div>
      )}

      {!show && (
        <object
          data={whitepaper}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem 1rem",
              width: "100vw",
              height: "100vh",
            }}
          >
            <p style={{ textAlign: "center" }}>
              Your web browser doesn't have a PDF plugin. Instead you can{" "}
              <a
                href="#"
                onClick={() => {
                  setshow(!show);
                }}
              >
                click here to download the PDF file.
              </a>
            </p>
          </div>
        </object>
      )}
    </div>
  );
}

export default WhitePaper;
