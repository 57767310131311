import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import review1 from "../images/1.jpeg";
import review2 from "../images/2.jpeg";
import review3 from "../images/3.jpeg";
import review4 from "../images/4.jpeg";
import review5 from "../images/5.jpg";
import review6 from "../images/6.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../styles.css";

// import required modules
import { Pagination } from "swiper";

function Swipers2() {
  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="">
              <div className="swiper swiper-testimonial-1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                  <div className="testimonials-box">
                     
                     <img src={review2} style={{width:"100%", height:"100%"}}/>
                     
                     </div>       
          
       
               </div>
               </div>
               </div>
              </div>
              
            </div>
     
          </div>

        </SwiperSlide>

        <SwiperSlide>
        <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="">
              <div className="swiper swiper-testimonial-1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                  <div className="testimonials-box">
                     
                     <img src={review1} style={{width:"100%", height:"100%"}}/>
                     
                     </div>       
          
       
               </div>
               </div>
               </div>
              </div>
              
            </div>
     
          </div>

        </SwiperSlide>


        <SwiperSlide>
        <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="">
              <div className="swiper swiper-testimonial-1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                  <div className="testimonials-box">
                     
                     <img src={review3} style={{width:"100%", height:"100%"}}/>
                     
                     </div>       
          
       
               </div>
               </div>
               </div>
              </div>
              
            </div>
         
          </div>

        </SwiperSlide> 

        <SwiperSlide>
        <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="">
              <div className="swiper swiper-testimonial-1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                  <div className="testimonials-box">
                     
                     <img src={review2} style={{width:"100%", height:"100%"}}/>
                     
                     </div>       
          
       
               </div>
               </div>
               </div>
              </div>
              
            </div>
 
          </div>

        </SwiperSlide>


        <SwiperSlide>
        <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="">
              <div className="swiper swiper-testimonial-1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                  <div className="testimonials-box">
                     
                     <img src={review4} style={{width:"100%", height:"100%"}}/>
                     
                     </div>       
          
       
               </div>
               </div>
               </div>
              </div>
              
            </div>
       
          </div>

        </SwiperSlide>

        <SwiperSlide>
        <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="">
              <div className="swiper swiper-testimonial-1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                  <div className="testimonials-box">
                     
                     <img src={review5} style={{width:"100%", height:"100%"}}/>
                     
                     </div>       
          
       
               </div>
               </div>
               </div>
              </div>
              
            </div>
       
          </div>

        </SwiperSlide>

        <SwiperSlide>
        <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="">
              <div className="swiper swiper-testimonial-1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                  <div className="testimonials-box">
                     
                     <img src={review6} style={{width:"100%", height:"100%"}}/>
                     
                     </div>       
          
       
               </div>
               </div>
               </div>
              </div>
              
            </div>
       
          </div>

        </SwiperSlide>
     
   
      </Swiper>
    </>
  );
}

export default Swipers2
